import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import theme from "../../utils/theme"

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: ${props => props.mt || "2rem"};
  padding-bottom: ${props => props.mb || "2rem"};
`

const ButtonAll = `
    font-weight: 700;
    font-size: 20px;
    justify-self: center;
    align-self: center;
    display: block;
    position: relative;
    text-decoration: none;
    padding: .7rem 4rem ;
    transition: all 0.4s ease-in-out;
    color: white;
    background-color: #006b39;
    border-radius: 30px;
    line-height:1.1;

    b {
        color: transparent;
      }

    &:hover {
        color: #00431a;
        background-color: #88c570;
      }
    
      :focus {
        border: 1px solid #4f6335;
      }
`
const BtnA = styled.a`
  ${ButtonAll}
`

const BtnL = styled(Link)`
  ${ButtonAll}
`

const BtnB = styled.button`
  ${ButtonAll}
`

const Button = ({
  children,
  to,
  href,
  target,
  state,
  onClick,
  color,
  colorHover,
  colorFont,
  colorFontHover,
  mb,
  mt,
}) => (
  <ButtonWrapper mt={mt} mb={mb}>
    {to ? (
      <BtnL
        color={color}
        color_hover={colorHover}
        color_font={colorFont}
        color_font_hover={colorFontHover}
        to={to}
      >
        {children}
      </BtnL>
    ) : href ? (
      <BtnA
        color_a={color}
        color_hover_a={colorHover}
        color_font_a={colorFont}
        color_font_hover_a={colorFontHover}
        href={href}
        target={target}
      >
        {children}
      </BtnA>
    ) : (
      <BtnB
        color_btn={color}
        color_hover_btn={colorHover}
        color_font_btn={colorFont}
        color_font_hover_btn={colorFontHover}
        onClick={onClick}
        state={state}
      >
        {children}
      </BtnB>
    )}
  </ButtonWrapper>
)

export default Button
