import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { down, up } from "styled-breakpoints"
import { Link } from "gatsby"
//DATA
import { layout } from "../assets/data/layout"
import film from "../assets/video/film3.mp4"
import { info } from "../assets/data/kontakt"
//STYLES
import { H1, H2, H3, H4, H5, H6, Text, LabelBW } from "../utils/typo"
import {
  Section2,
  ContainerMob,
  Container,
  CardWrapperMob,
  CardWrapperFull,
  ImageGallery,
} from "../utils/utils"
//COMPONENTS
import Seo from "../components/seo/Seo"
import Button from "../components/button/Button"
import SeoImage from "../components/seo/SeoImage"
import Video from "../components/video/Video"
import { theme } from "../utils/theme"

const Baner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  > div:first-of-type {
    align-self: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  h1 {
    word-break: break-word;
  }
`

const CardMain = styled.div`
  background-color: white;
  outline: 1px solid green;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h4 {
    position: relative;
    margin-bottom: 1rem;
    :before {
      content: "";
      position: absolute;
      top: calc(50% - 6px);
      left: -2rem;
      height: 12px;
      width: 1.6rem;
      background-color: ${({ theme }) => theme.color.base3};
    }
  }

  > div:nth-of-type(1) {
    display: flex;
    flex-direction: column;
    > div:nth-of-type(1) {
      margin-bottom: 1rem;
      min-width: 50px;
      width: 70%;
      max-width: 100px;
      align-self: center;
    }
  }

  > div:nth-of-type(2) {
    padding: 0;
    padding-top: 1rem;
    a {
      padding: 0.5rem 1.7rem;
    }
  }

  ${down("sm")} {
    padding: 1rem;
    h4 {
      :before {
        left: -1rem;
        width: 0.6rem;
      }
    }

    h4 {
      word-break: break-all;
      margin-top: 1.5rem;
    }

    p {
      hyphens: auto;
      word-break: break-word;
    }
  }
`

export default function Home() {
  return (
    <div>
      <Seo title="Rotkegel - producent roślin najwyższej jakości" slug="/" />
      <SeoImage />
      <Video
        videoSrcURL={film}
        videoTitle="Rotkegel - ogrodnictwo, szkółka, rośliny"
      />

      <ContainerMob>
        <Baner>
          <StaticImage
            src={`../assets/images/icon_white.png`}
            alt="Produkujemy"
            layout="fixed"
            objectFit="cover"
            placeholder="blurred"
            width={60}
          />
          <H1 colorFont="white" textAlign="center">
            Wypielęgnowane rośliny specjalnie dla <br />
            <span>Twojego ogrodu</span>
          </H1>
        </Baner>
      </ContainerMob>

      <Container mt="8rem" mtMobile="2rem">
        <CardWrapperFull xl="4" m="4">
          <CardMain>
            <div>
              <StaticImage
                src={`../assets/images/produkujemy.png`}
                alt="Produkujemy"
                layout="fullWidth"
                objectFit="cover"
                placeholder="blurred"
                aspectRatio={1}
              />
              <div>
                <H4>Produkujemy</H4>
                <Text>
                  Najwyższej jakości rośliny ogrodowe, starannie pielęgnowane
                  pod okiem wyspecjalizowanych i doświadczonych pracowników.
                </Text>
              </div>
            </div>
            <Button to="/#produkujemy">Więcej</Button>
          </CardMain>

          <CardMain>
            <div>
              <StaticImage
                src={`../assets/images/sprzedajemy.png`}
                alt="Sprzedajemy"
                layout="fullWidth"
                objectFit="cover"
                placeholder="blurred"
                aspectRatio={1}
              />
              <div>
                <H4>Sprzedajemy</H4>
                <Text>
                  Tylko sprawdone produkty, posiadamy bardzo bogaty asortyment,
                  który zadowoli każdego!
                </Text>
              </div>
            </div>
            <Button to="/#sprzedajemy">Więcej</Button>
          </CardMain>

          <CardMain>
            <div>
              <StaticImage
                src={`../assets/images/doradzamy.png`}
                alt="Doradzamy"
                layout="fullWidth"
                objectFit="cover"
                placeholder="blurred"
                aspectRatio={1}
              />
              <div>
                <H4>Doradzamy</H4>
                <Text>
                  Wysoko wykwalifikowani pracownicy z pasją i przyjemnością
                  służą pomocą.
                </Text>
              </div>
            </div>
            <Button to="/#doradzamy">Więcej</Button>
          </CardMain>

          <CardMain>
            <div>
              <StaticImage
                src="../assets/images/dostarczamy.png"
                alt="Dostarczamy"
                layout="fullWidth"
                objectFit="cover"
                placeholder="blurred"
                aspectRatio={1}
              />
              <div>
                <H4>Dostarczamy</H4>
                <Text>
                  Nie musisz się martwić rozmiarami drzew, czy też pobrudzoną
                  tapicerką. Rośliny dostarczamy prosto do ogrodu.
                </Text>
              </div>
            </div>
            <Button to="/#dostarczamy">Więcej</Button>
          </CardMain>
        </CardWrapperFull>
      </Container>

      <Section2 pt="7" pb="7" id="doradzamy">
        <CardWrapperMob xl="2" l="2" m="2" colGap="3rem">
          <StaticImage
            src="../assets/images/baner.png"
            alt="Dostarczamy"
            layout="fullWidth"
            objectFit="cover"
            placeholder="blurred"
            aspectRatio={1}
          />
          <div>
            <H2>Zawsze możesz liczyć na naszą wiedzę i doświadczenie</H2>
            <Text mt="2rem" mb="1rem">
              <b>
                Indywidualne podejście do klienta - dla nas to nie jest tylko
                zwykły slogan! U Nas BEZPŁATNIE dowiesz się, które rośliny i
                rozwiązania będą idealne dla Twojego ogrodu.
              </b>
            </Text>
            <ul>
              <li>
                Dopasowujemy rośliny odpowiednie do każdego zakątka ogrodu.
              </li>
              <li>
                Zapraszamy do odwiedzin naszej szkółki wraz ze szkicem ogrodu
                lub miejsca, które mamy odmienić. Oferujemy nasze wieloletnie
                doświadczenie w aranżaccji ogrogów <b>BEZPŁATNIE</b>
              </li>
            </ul>
          </div>
        </CardWrapperMob>
      </Section2>

      <Section2 pt="7" pb="7" bgcolor={theme.color.sec9} id="produkujemy">
        <ContainerMob>
          <H2>Jesteśmy producentem roślin</H2>
          <Text mt="2rem" mb="1rem">
            <b>
              Nasze rośliny są stale pielągnowane - ponieważ są produkowane u
              nas.
            </b>
          </Text>
          <Text>
            Rośliny w naszym gospodarstwie są pod stałą opieką wykwalifikowanych
            i pełnych pasji ogrodników z wieloletnim doświadczeniem.
          </Text>
        </ContainerMob>
      </Section2>

      <ImageGallery>
        <StaticImage
          src="../assets/images/9.jpg"
          alt="Obraz na dzień kobiet - niezapominajki"
          layout="fullWidth"
          objectFit="cover"
          placeholder="blurred"
          aspectRatio={1}
        />
        <StaticImage
          src="../assets/images/2.jpg"
          alt="Obraz z kwiatami róży"
          layout="fullWidth"
          objectFit="cover"
          placeholder="blurred"
          aspectRatio={1}
        />
        <StaticImage
          src="../assets/images/8.jpg"
          alt="Zestaw obrazów z kwiatami"
          layout="fullWidth"
          objectFit="cover"
          placeholder="blurred"
          aspectRatio={1}
        />
        <StaticImage
          src="../assets/images/7.jpg"
          alt="Obraz z kwiatami - róża"
          layout="fullWidth"
          objectFit="cover"
          placeholder="blurred"
          aspectRatio={1}
        />
        <StaticImage
          src="../assets/images/6.jpg"
          alt="Obraz z kwiatami frezji"
          layout="fullWidth"
          objectFit="cover"
          placeholder="blurred"
          aspectRatio={1}
        />
        <StaticImage
          src="../assets/images/5.jpg"
          alt="Komplet obrazów na dzień kobiet"
          layout="fullWidth"
          objectFit="cover"
          placeholder="blurred"
          aspectRatio={1}
        />
        <StaticImage
          src="../assets/images/4.jpg"
          alt="Komplet obrazów na dzień kobiet"
          layout="fullWidth"
          objectFit="cover"
          placeholder="blurred"
          aspectRatio={1}
        />
      </ImageGallery>

      <Section2 pt="7" pb="7" id="dostarczamy">
        <CardWrapperMob xl="2" l="2" m="2" colGap="3rem">
          <StaticImage
            src="../assets/images/10.jpg"
            alt="Dostarczamy"
            layout="fullWidth"
            objectFit="cover"
            placeholder="blurred"
            aspectRatio={1}
          />
          <div>
            <H2>Dostawa</H2>
            <Text mt="2rem" mb="1rem">
              <b>
                Nie musisz martwić się dostawą roślin, ponieważ dysponujemy
                odpowiednim sprzętem i doświadczeniem, które zapewnia bezpieczny
                transport nawet okazałych drzew i krzewów.
              </b>
            </Text>
            <Text>
              <b>Ponadto posiadamy:</b>
            </Text>
            <ul>
              <li>doskonały dojazd z DK1,</li>
              <li>parking tuż przy wejściu,</li>
              <li>możliwość wjazdu na teren szkółki dowolnym pojazdem,</li>
              <li>sprzęt ułatwiający załadunek.</li>
            </ul>
          </div>
        </CardWrapperMob>
      </Section2>

      <Section2 pt="7" pb="7" id="sprzedajemy" bgcolor={theme.color.sec9}>
        <ContainerMob>
          <H2>Sprzedaż</H2>
          <Text mt="2rem" mb="1rem">
            <b>
              U nas możesz przed zakupem zobaczyć rośliny dokładnie w takiej
              formie, jak będą wyglądały po wsadzeniu do ogrodu. Jest to
              możliwe, ponieważ nasz system uprawy stanowi doskonałą ekspozycję,
              a zarazem zapewnia bardzo dobre warunki dla roślin.
            </b>
          </Text>
          <ul>
            <li>doradztwo i fachowa obsługa,</li>
            <li>bogaty asortyment,</li>
            <li>rośliny sezonowe,</li>
            <li>rośliny najmodniejsze w nowoczesnych ogrodach,</li>
            <li>wypielęgnowane rośliny, najwyżaszej jakości.</li>
          </ul>
        </ContainerMob>
      </Section2>
    </div>
  )
}
