import React from "react"
import styled from "styled-components"
import { down, up } from "styled-breakpoints"

const VideoWrapper = styled.div`
  width: 100%;
  height: 700px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;

  ${down("md")} {
    width: auto;
    height: 500px;
    overflow: hidden;
  }

  ${down("sm")} {
    height: 350px;
    width: auto;
  }

  ${down("xs")} {
    height: 400px;
    width: 100%;
  }

  video {
    width: 100%;
    height: auto;

    ${down("xs")} {
      height: 400px;
      width: auto;
    }
  }

  :before {
    content: "";
    background: rgb(0, 46, 12);
    background: linear-gradient(
      180deg,
      rgba(0, 46, 12, 0.85) 0%,
      rgba(24, 77, 0, 0.75) 13%,
      rgba(96, 143, 11, 0.65) 100%
    );
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
  }
`

const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <VideoWrapper>
    <video autoPlay={true} muted={true} loop={true} id="myVideo">
      <source src={videoSrcURL} type="video/mp4" />
      Your browser does not support HTML5 video.
    </video>
  </VideoWrapper>
)
export default Video
